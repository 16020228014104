
import { Component, Vue } from "vue-property-decorator";
import { HousfyPropertyCardShimmer } from "housfy-ui-lib";

@Component({
  name: "OwnerPropertiesShimmer",
  components: {
    HousfyPropertyCardShimmer,
  },
})
export default class OwnerPropertiesShimmer extends Vue {}
